var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      { staticClass: "container-fluid px-3 px-md-5 visitas-main" },
      [
        _c(
          "div",
          {
            staticClass: "spainhn-solid-red-background d-flex py-3",
            staticStyle: {
              height: "auto",
              "min-height": "75px",
              width: "100%",
            },
          },
          [
            _c(
              "p",
              { staticClass: "my-auto ps-4 spainhn-subtitle-2 text-white" },
              [_vm._v(_vm._s(_vm.$t("default.MENSAJE_SERVICIOS_ESPACIOS_1")))]
            ),
          ]
        ),
        _c("div", { staticClass: "text-center my-4 spainhn-subtitle-3" }, [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("default.MENSAJE_SERVICIOS_ESPACIOS_2"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "d-flex mb-5" },
          [
            _c("b-select", {
              staticClass: "mt-2 spainhn-custom-input me-3 w-100",
              attrs: { options: _vm.comunidadesAutonomas },
              on: { change: _vm.filterByComunidad },
              model: {
                value: _vm.localizacionFilter,
                callback: function ($$v) {
                  _vm.localizacionFilter = $$v
                },
                expression: "localizacionFilter",
              },
            }),
            _c("b-select", {
              staticClass:
                "mt-2 spainhn-custom-input mx-1 w-100 overflow-hidden",
              attrs: { options: _vm.rutasOptionsFiltered },
              on: { change: _vm.filterByRutas },
              model: {
                value: _vm.rutaFilter,
                callback: function ($$v) {
                  _vm.rutaFilter = $$v
                },
                expression: "rutaFilter",
              },
            }),
            _c("b-select", {
              staticClass: "mt-2 spainhn-custom-input ms-3 w-100",
              attrs: { options: _vm.monumentosFiltered },
              on: { change: _vm.filterByMonument },
              model: {
                value: _vm.castilloFilter,
                callback: function ($$v) {
                  _vm.castilloFilter = $$v
                },
                expression: "castilloFilter",
              },
            }),
          ],
          1
        ),
        _c("b-spinner", {
          staticClass: "d-flex mx-auto",
          class: { "d-none": !_vm.loading },
          attrs: { variant: "danger" },
        }),
        _c("div", { staticClass: "d-flex mb-5" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
            },
            _vm._l(_vm.edificiosFiltered, function (edificio, index) {
              return _c("cards-2", {
                key: index,
                attrs: {
                  monument: edificio.webName,
                  provincia: edificio.provincia,
                  municipio: edificio.municipio,
                  imageUrl: `${_vm.imagenesUrl}/monumentos/${edificio.oldId}/${edificio.imagen}`,
                  text: edificio.webDescription,
                  id: edificio.id,
                  nombreUrl: edificio.nombreNormalizado,
                  extendUrl: "#services",
                  role: "button",
                },
              })
            }),
            1
          ),
        ]),
        _c("div", { staticClass: "d-flex mb-5" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
            },
            _vm._l(_vm.edificiosFilteredWithFilter, function (edificio, index) {
              return _c("cards-2", {
                key: index,
                attrs: {
                  monument: edificio.webName,
                  provincia: edificio.provincia,
                  municipio: edificio.municipio,
                  imageUrl: `${_vm.imagenesUrl}/monumentos/${edificio.oldId}/${edificio.imagen}`,
                  text: edificio.webDescription,
                  id: edificio.id,
                  nombreUrl: edificio.nombreNormalizado,
                  extendUrl: "#services",
                  role: "button",
                },
              })
            }),
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }